import React, { Fragment } from 'react';
import Helmet from 'react-helmet';

import './Form.css';


class Form extends React.Component {
  static defaultProps = {
    name: 'De Zoete Fee - Contact formulier',
    _subject: 'De Zoete Fee',
    action: 'https://formspree.io/f/mzbkebbd',
    method: 'POST',
    successMessage: 'Bedankt voor uw bericht, we nemen zo spoedig mogelijk contact met u op.',
    errorMessage:
      'Oei, er is een probleem waardoor uw bericht niet kan doorgestuurd worden. Gelieve ons te contacteren via e-mail.'
  }

  state = {
    alert: '',
    disabled: false
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.disabled) return

    const form = e.target
    //const data = serialize(form)
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();

    this.setState({ disabled: true })

    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      //console.log(xhr.response);
      if (xhr.readyState !== XMLHttpRequest.DONE){
        //console.log('Network error');
      } 
      else {
        //return xhr.response;
        //throw new Error('Network error');
      }
      if (xhr.status === 200) {
        form.reset();
        this.setState({
          alert: this.props.successMessage,
          disabled: false
        });
      } 
      else {
        console.error(xhr.errorMessage);
        this.setState({
          disabled: false,
          alert: this.props.errorMessage
        });
      }
    };
    xhr.send(data);
  }

  render() {
    const { name, _subject, action, method } = this.props

    return (
      <Fragment>
        <Helmet>
          <script src="https://www.google.be/recaptcha/api.js" />
        </Helmet>
        <form
          className="Form"
          name={name}
          action={action}
          _subject={_subject}
          method={method}
          onSubmit={this.handleSubmit}
        >
          {this.state.alert && (
            <div className="Form--Alert">{this.state.alert}</div>
          )}
          <div className="Form--Group">
            <label className="Form--Label">
              <input
                className="Form--Input Form--InputText"
                type="text"
                placeholder="Voornaam"
                name="voornaam"
                required
              />
              <span>Voornaam</span>
            </label>
            <label className="Form--Label">
              <input
                className="Form--Input Form--InputText"
                type="text"
                placeholder="Achternaam"
                name="achternaam"
                required
              />
              <span>Achternaam</span>
            </label>
          </div>
          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="text"
              placeholder="E-mail"
              name="email"
              required
            />
            <span>E-mailadres</span>
          </label>
          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="phone number"
              placeholder="Telefoonnummer (optioneel)"
              name="phone"
            />
            <span>Telefoonnummer (optioneel)</span>
          </label>
          <label className="Form--Label email">
            <input
              className="Form--Input Form--InputText"
              type="text"
              placeholder="E-mail"
              name="_gotcha"
            />
          </label>
          <label className="Form--Label has-arrow">
              Type aanvraag:
            <select
              className="Form--Input Form--Select"
              name="_subject"
              //defaultValue="Betreft"
              required
            >
              <option disabled>
                Type aanvraag
              </option>
              <option>Algemeen</option>
              <option>Offerte</option>
              <option>Feedback of suggestie</option>
            </select>
          </label>
          <label className="Form--Label">
            <textarea
              className="Form--Input Form--Textarea Form--InputText"
              placeholder="Typ uw bericht"
              name="bericht"
              rows="10"
              required
            />
            <span>Uw bericht</span>
          </label>
          {!!_subject && <input type="hidden" name="_subject" value={_subject} />}
          <input type="hidden" name="formulier" value={name} />
          <input
            className="Button Form--SubmitButton"
            type="submit"
            value="Versturen"
            disabled={this.state.disabled}
          />
        </form>
      </Fragment>
    );
  };
};

export default Form;
