import React from 'react';
import Marked from 'react-markdown';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

import './Content.css';

const encodeMarkdownURIs = (source = '') => {
  const markdownLinkRegex = /\[(.+)\]\((.+)(".+)\)/g
  return source.replace(markdownLinkRegex, (match, linkURI) => {
    if (!linkURI) return match
    const replaced = match.replace(linkURI, encodeURI(linkURI))
    return replaced
  })
};

const HtmlBlock = ({ value }) => {
  const sanitizer = (html) => {
    return sanitizeHtml(html);
  };

  if (value.indexOf('<iframe') !== 0) return value
  return (
    <div
      className={`Content--Iframe`}
      dangerouslySetInnerHTML={{
        __html: sanitizer(value)
      }}
    />
  );
};

const Content = ({ source, src, className = '' }) => {
  // accepts either html or markdown
  source = source || src || ''

  return (
    <Marked
      className={`Content ${className}`}
      source={encodeMarkdownURIs(source)}
      renderers={{
        //image: MyImage,
        html: HtmlBlock
      }}
    />
  );
};

Content.propTypes = {
  source: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string
}

export default Content;
