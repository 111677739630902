import React from "react"
import ContactPage from "../components/Contact/ContactPage"
import SEO from "../components/SEO/SEO"

const ContactPagina = () => (
  <>
    <SEO title="Contact" />
    <ContactPage />
  </>
)

export default ContactPagina
