import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { MapPin } from 'react-feather';

import './GoogleMap.css';


let mapkey = '';
if (process.env.GATSBY_GOOGLE_MAPS_API_KEY) {
  mapkey = process.env.GATSBY_GOOGLE_MAPS_API_KEY;
}

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 50.963444,
      lng: 3.753444
    },
    zoom: 12
  }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: mapkey }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <Marker lat={50.963444} lng={3.753444} text={'De Zoete Fee'} />
        </GoogleMapReact>
      </div>
    );
  };
};

export default GoogleMap;

const Marker = () => {
  return (
    <div style={{ color: 'red' }}>
      <MapPin />
    </div>
  );
};
