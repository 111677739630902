import React from 'react';
import PropTypes from "prop-types";
import { MapPin, Smartphone, Mail, Facebook, Bold } from 'react-feather';
import GoogleMap from '../GoogleMap/GoogleMap';
import Form from '../Form/Form';
import Content from '../Content/Content';

import './ContactPage.css';


const ContactPage = ( { contactBody, address, fb, phone1, phone2, phone1_name, phone2_name, email, btw } ) => (

  <section className="section Contact--Section1">
      <div className="container Contact--Section1--Container">
        <div>
        <Content source={ contactBody } />
          <div className="Contact--Details">
            {{address} && (
              <a
                className="Contact--Details--Item"
                href={`https://www.google.be/maps/search/${encodeURI(
                  address
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MapPin /> { address }
              </a>
            )}
            {fb && (
              <a 
                className="Contact--Details--Item" href={`https://www.facebook.com/${fb}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook /> Vind ons ook op facebook!
              </a>
            )}
            {phone1 && (
              <a className="Contact--Details--Item" href={ `tel:${phone1}` }>
                <Smartphone /> {phone1}{phone1_name}
              </a>
            )}
            {phone2 && (
              <a className="Contact--Details--Item" href={ `tel:${phone2}` }>
                <Smartphone /> {phone2}{phone2_name}
              </a>
            )}
            {email && (
              <a className="Contact--Details--Item" href={ `mailto:${email}` }>
                <Mail /> {email}
              </a>
            )}
            {btw && (
              <a 
                className="Contact--Details--Item"
                href={`https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?nummer=${encodeURI(
                  btw.replace("BE","").replace(/\s/g, "").replace(/\./g, "")
              )}`} 
              target="_blank"
              rel="noopener noreferrer"
              >
                <Bold />BTW {btw}
              </a>
            )}
          </div>
        </div>
        <div>
          <Form />
        </div>
      </div>
      <GoogleMap />
    </section>
);

ContactPage.propTypes = {
  contactBody: PropTypes.string,
  address: PropTypes.string,
  fb: PropTypes.string,
  phone1: PropTypes.string,
  phone1_name: PropTypes.string,
  phone2: PropTypes.string,
  phone2_name: PropTypes.string,
  email: PropTypes.string,
  btw: PropTypes.string,
}

ContactPage.defaultProps = {
  contactBody:
  `Neem gerust contact op via mail, telefoon of het formulier op deze pagina en stel uw vraag!`,
  address: `Sint-Annastraat 1, 9820 Merelbeke`,
  fb: `dezoetefee`,
  phone1: `0494/70 15 10`,
  phone1_name: ` (Nicky)`,
  phone2: `0497/26 04 27`,
  phone2_name: ` (Evelyn)`,
  email: `info@dezoetefee.be`,
  btw: `BE 0754 487 576`,
}

export default ContactPage;
